// i18next-extract-mark-ns-start sepa-direct-debit

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import sepa_monei from 'images/sepa_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {List} from 'components/List';
import {IndexBackground} from 'components/landings/IndexBackground';
import OrderList from 'components/landings/OrderList';
import SmallText from 'components/SmallText';
import {PaymentMethodsLinkList} from 'components/landings/PaymetMethodsLinkList';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {BlogLink} from 'components/links/Blog';
import {IntegrationGuide} from 'components/payment-methods/IntegrationGuide';
import {InternalPageLink} from 'components/links/Pages';

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const SepaDirectDebit: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('SEPA Direct Debit for e-commerce')}
        description={t(
          'Expand your e-commerce business across Europe with SEPA Direct Debit. Process one-time or recurring payments from 36 countries. Get MONEI »'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '590px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>
                  SEPA Direct Debit for <br />
                  e-commerce
                </Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Introduced by the European Payments Council SEPA (Single Euro Payments Area) Direct
                Debit is a Europe-wide system that supports direct debit euro payments in 36
                countries. Accepting it in your e-commerce store is crucial if you’re looking to
                expand your business across the region. Use SEPA Direct Debit to accept one-time or
                recurring payments from European consumers.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg src={sepa_monei} alt="MONEI SEPA" title="MONEI SEPA" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept cross-border payments with SEPA Direct Debit</Trans>
              </SectionHeader>

              <Trans parent="p">
                Easily access more European markets by accepting SEPA Direct Debit in your store and
                enjoy these benefits:
              </Trans>
              <List>
                <Trans parent="li">
                  Increased customer retention due to fast, flexible, and convenient payments
                </Trans>
                <Trans parent="li">
                  Reduced subscription admin (for you) and improved security (for customers)
                </Trans>
                <Trans parent="li">
                  Streamlined and cost effective cross-border payment processing
                </Trans>
                <Trans parent="li">
                  Improved cash flow and settlement of all EU payments into one bank account
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Try MONEI</Trans>
                </SignUpButton>
              </SectionActions>
              <Trans>
                <SmallText>
                  Reach consumers in the following countries: AD, AT, BE, BG, CH, CY, CZ, DE, DK,
                  EE, ES, FI, FR, GB, GR, HR, HU, IE, IS, IT, LI, LT, LU, LV, MC, MT, NL, NO, PL,
                  PT, RO, SE, SI, SK, SM, VA
                </SmallText>
              </Trans>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>How does SEPA Direct Debit work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                SEPA Direct Debit only requires customers to make a few clicks before their payment
                is complete (i.e., higher conversion rates for you).
              </Trans>
              <OrderList>
                <Trans parent="li">Customer goes to checkout</Trans>
                <Trans parent="li">
                  Customer selects SEPA Direct Debit from the payment methods list
                </Trans>
                <Trans parent="li">Customer provides their IBAN and name, and clicks Pay</Trans>
                <Trans parent="li">Payment is complete</Trans>
              </OrderList>
              <Trans>
                With <BlogLink slug="what-is-sepa">SEPA Direct Debit</BlogLink> payments, you’ll reach more European consumers, improve the
                payment experience, and increase sales. But diversifying your payment stack
                shouldn’t end there. Accept{' '}
                <InternalPageLink slug="payment-methods">many payment methods</InternalPageLink> including
                <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink> and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                like{' '}
              </Trans>
              <PaymentMethodsLinkList exclude={[]} endTag={t('and')} />.
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get Started</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
        </Content>
      </Background>
      <IntegrationGuide title={<Trans>Integrate SEPA Direct Debit</Trans>}>
        <Trans>
          Adding SEPA Direct Debit to your e-commerce store is easy. All you have to do is open your
          MONEI account — then once you’ve been approved,{' '}
          <AnchorLink href="https://support.monei.com/hc/requests/new">contact support</AnchorLink>{' '}
          to activate this payment method on all major e-commerce platforms or your customer-built
          website.
        </Trans>
      </IntegrationGuide>
    </>
  );
};

export default SepaDirectDebit;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "sepa-direct-debit"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
